.App {
    margin: auto;
    display: flex;
    flex-direction: column;
    height: auto !important; /* real browsers */
    min-height: 100vh; /* IE6: treaded as min-height*/
}

body, html {
    font-family: 'Lato';
    font-size: 14px;
    overflow-x: hidden;
}

hr {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.hoverable:hover {
    background-color: whitesmoke;
}

#whiteFont {
    color: white;
}